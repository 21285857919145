<template>
  <v-sheet class="d-flex align-center justify-center fill-height">
    <v-app-bar app color="primary" dark class="pa-0">
      <v-img
        class="flex-grow-1"
        src="/logo-half-white.svg"
        height="50px"
        width="170px"
        contain
        position="center left"
      />

      <div v-if="$vuetify.breakpoint.mdAndUp">
        <v-btn
          v-for="([text, ref], i) in items"
          :key="i"
          @click="open(ref)"
          small
          text
          class="py-5"
        >
          {{ text }}
        </v-btn>
      </div>

      <v-app-bar-nav-icon v-else @click="drawer = !drawer" class="mr-2" />
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      right
      temporary
      light
      color="grey lighten-5"
    >
      <v-img height="100px" src="/eConsenso.png" contain class="mt-4 mb-2" />

      <v-list>
        <v-divider />
        <v-list-item-group v-for="([text, ref], i) in items" :key="i">
          <v-list-item
            @click="
              open(ref);
              drawer = false;
            "
            link
          >
            <v-list-item-content class="text-subtitle-2 font-weight-medium">
              {{ text }}
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-divider />
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-container style="max-width: 1000px" class="pb-0 fill-height">
      <v-row no-gutters style="width: 100%">
        <v-col
          cols="12"
          md="6"
          lg="6"
          xl="6"
          :class="{
            'text-center': $vuetify.breakpoint.smAndDown,
            'text-justify': $vuetify.breakpoint.mdAndUp,
          }"
        >
          <div
            class="d-flex flex-column justify-center fill-height align-center"
          >
            <p class="title1 primary--text text-center" style="line-height: 1">
              eCONSENSO.<br />
              Mais Segurança.<br />
              Menos Burocracia.
            </p>
            <p class="subtitle1 greyApp--text mt-4">
              Através do Aplicativo eCONSENSO você poderá responder às
              solicitações de consentimentos de uso dos seus Dados Pessoais.
              Tudo isso de forma Totalmente Segura e Sem Burocracia. Baixe
              Agora:
            </p>
            <div class="mt-4 d-flex">
              <a
                href="https://play.google.com/store/apps/details?id=br.com.econsenso"
                target="_blank"
                rel="noopener noreferrer"
                style="border: none; text-decoration: none"
                ><v-img
                  src="@/assets/landing-page/google-store.png"
                  width="150px"
                />
              </a>
              <a
                class="ml-4"
                href="https://apps.apple.com/br/app/econsenso/id1579564350"
                target="_blank"
                rel="noopener noreferrer"
                style="border: none; text-decoration: none"
                ><v-img
                  src="@/assets/landing-page/apple-store.png"
                  max-width="150px"
              /></a>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-img
            v-if="$vuetify.breakpoint.mdAndUp"
            position="center right"
            src="@/assets/landing-page/phone.png"
            height="auto"
            max-height="600px"
            max-width="100%"
            contain
          />
          <v-img
            v-else
            position="center bottom"
            src="@/assets/landing-page/phoneCut.png"
            max-width="100%"
            max-height="200px"
            contain
          />
        </v-col>
      </v-row>
    </v-container>
    <Footer />
  </v-sheet>
</template>

<script>
import Footer from "@/layout/default/Footer.vue";

export default {
  name: "LandingInstallApp",
  components: {
    Footer,
  },

  data() {
    return {
      drawer: false,
      items: [["Visitar Site", "landing"]],
    };
  },

  methods: {
    open(ref) {
      this.$router.replace({
        name: ref,
      });
    },
  },
};
</script>

<style scoped>
.break {
  word-break: break-word;
}
.title1 {
  font-size: 40px;
  font-weight: bold;
}
.subtitle1 {
  font-size: 21px;
}
</style>
